<template>
  <div class="slider-container relative flex flex-col gap-6">
    <Splide ref="splideEl" :options="splideOptions">
      <slot></slot>
    </Splide>
  </div>
</template>
<script lang="ts" setup>
import { PropType, computed as vComputed } from "vue";
import { Splide } from "@splidejs/vue-splide";
import { splideDefaultOptions } from "~/components/BaseSlider/const";
import { SplideOptions, sliderVariant } from "~/components/BaseSlider/types";

const props = defineProps({
  options: {
    type: Object as PropType<SplideOptions>,
    default: () => splideDefaultOptions,
  },
  variant: {
    type: String as PropType<sliderVariant>,
    default: undefined,
  },
});

const splideOptions = vComputed(() => ({
  ...{
    arrows: props.variant === "arrows",
    pagination: props.variant === "pagination",
  },
  autoplay: props.variant === "pagination",
  ...props.options,
}));
</script>
<style lang="scss" scoped>
:deep(.splide__pagination__page) {
  @apply w-3 h-3 opacity-30 bg-white;
}
:deep(.splide__pagination__page) {
  @apply w-3 h-3 opacity-30 bg-white;
}
:deep(.splide__pagination__page.is-active) {
  @apply transform-none opacity-100;
}
:deep(.splide__pagination.splide__pagination--ltr) {
  @apply p-0 relative mt-5 bottom-0 flex;
}
:deep(.splide__arrow--prev) {
  @apply left-0;
}
:deep(.splide__arrow--next) {
  @apply right-0;
}
:deep(.splide__arrow) {
  @apply bg-transparent bg-gradient-to-l from-black-800 via-black-800 to-transparent rounded-none w-16 h-full  justify-end;

  svg {
    @apply fill-white;
  }
}
:deep(.splide__arrow--prev) {
  @apply bg-gradient-to-r justify-start;
}
:deep(.splide__pagination.justify-start) {
  justify-content: flex-start;
}
:deep(.splide__pagination.justify-center) {
  justify-content: flex-center;
}
:deep(.splide__pagination.justify-end) {
  display: flex;
  justify-content: flex-end;
}
:deep(.smaller-width) {
  @apply mt-3.5;
  button {
    width: 10px;
    height: 10px;
  }
}
:deep(.splide__pagination.absolute-position) {
  @apply lg:absolute w-max left-4 lg:left-10 bottom-14 lg:bottom-12 2xl:bottom-16;
}
</style>
